<template>

  <div class="form-compact form-compact-campaign-job-publication">

    <div class="form-compact-header">
      <v-system-bar dark color="primary">
        <v-icon class="mr-1">{{ $t('CAMPAIGN_JOB.ICON') }}</v-icon>
        <template v-if="id">
          <small><strong class="text-uppercase">{{ $t('CAMPAIGN_JOB.FORM_TITLE_UPDATE') }}</strong></small>
          <v-icon class="ml-4" size="12">mdi-pound</v-icon>
          <small>{{ loaded_values.id }}</small>
        </template>
        <small v-else><strong class="text-uppercase">{{ $t('CAMPAIGN_JOB.FORM_TITLE_CREATE') }}</strong></small>
        <v-spacer/>
        <v-icon
          small
          class="clickable"
          @click="$emit('close')"
        >
          mdi-close
        </v-icon>
      </v-system-bar>

      <v-toolbar
        color="transparent"
        flat
        height="66px"
      >
        <v-toolbar-title class="primary--text">
          {{ this.id ? this.loaded_values.title : $t('CAMPAIGN_JOB.FORM_TITLE_NEW') }}
        </v-toolbar-title>
        <v-spacer />
        <div class="mr-6" >
          <status-label :item="loaded_values"/>
        </div>
        <div class="mr-6 text-nowrap" v-if="ids.length > 1">
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                x-small
                depressed
                color="grey"
                :disabled="!canPrev"
                @click="prevItem"
                class="mr-2"
              >
                <v-icon color="white">mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.PREVIOUS') }}
          </v-tooltip>
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                x-small
                depressed
                color="grey"
                :disabled="!canNext"
                @click="nextItem"
              >
                <v-icon color="white">mdi-arrow-right</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.NEXT') }}
          </v-tooltip>
        </div>
        <div class="mr-6 d-flex text-nowrap" v-if="id">
          <div>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="error"
                  class="mr-2"
                  @click="removeItem"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.REMOVE') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="warning"
                  class="mr-2"
                  @click="resetItem"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.RESET') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="info"
                  @click="refreshItem"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              {{ $t('CAMPAIGN_JOB.REFRESH') }}
            </v-tooltip>
          </div>
        </div>
        <v-tooltip bottom transition="none">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              depressed
              color="success"
              @click="ids.length > 1 ? showSaveMultiple = true : saveItem"
              :disabled="!valid"
            >
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </template>
          {{ $t('COMMON.SAVE') }}
        </v-tooltip>
      </v-toolbar>
      <div class="toolbar-separator primary ml-4 mr-4"/>

      <v-dialog v-model="showSaveMultiple" width="350px" overlay-color="transparent">
        <v-card class="pa-4">
          <p class="ma-6 primary--text">
            {{$tc('COMMON.CONFIRM_MULTIPLE_ITEMS', this.ids.length)}}
          </p>
          <div class="d-flex">
            <v-btn rounded depressed large @click="showSaveMultiple = false">
              <v-icon class="mr-2">mdi-close</v-icon>{{$t('COMMON.CANCEL')}}
            </v-btn>
            <v-spacer />
            <v-btn color="success" rounded depressed large @click="saveItem(true)">
              <v-icon class="mr-2">mdi-check</v-icon>{{$t('COMMON.CONFIRM')}}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>

    </div>

    <div :class="['form-compact-content']">
      <Loading :value="!!loading" />
      <div class="pa-0 height-100" v-if="loaded_values">
        <List
          class="pl-4 pr-4"
          :items="loaded_values.publications"
          :count="count"
          :offset="offset"
          :limit="limit"
          :pagination="true"
          :loading="loading"
          :selected="selected"
          background="255,255,255"
          @applyFilters="applyFilters"
          @resetFilters="resetFilters"
          @select="selectItems"
          @page="changePage"
          @click="selectItem"
          @dblclick="editItem"
          @reload="searchItems"
        >
        <template v-slot:header="">
          allo
          <!-- <v-btn icon @click="searchItems"><v-icon>mdi-reload</v-icon></v-btn> -->
        </template>
        <template v-slot:nav="">

          <v-btn rounded depressed small color="primary" @click="searchItems">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span>Ajouter publications</span>
          </v-btn>
        </template>
        <template v-slot:item-list="item">
          <div v-if="item.status_type && (item.status_type === 'error' || item.status_type === 'info')" :class="['list-overlay', item.status_type ? item.status_type : '']"/>

          <v-list-item-action class="pr-2">
            <v-img
              contain
              width="50px"
              :src="getImage(item.channel)"
            />
          </v-list-item-action>
          <v-divider vertical />

          <v-list-item-action class="pl-2 pr-2 list-price">
            <div class="text-small grey--text text-uppercase"><small>{{item.method}}</small></div>
            <div v-if="item.method !== 'credit'">{{(+item.price || 0).toFixed(2)}} €</div>
            <div v-else>{{item.price}} Cr.</div>
          </v-list-item-action>
          <v-divider vertical />
          <v-list-item-content class="pl-4">
            <!--

            <v-list-item-title class="primary--text">
              <strong>{{item.job.title}}</strong>
              <v-icon
                v-if="item.job.parent_id"
                :title="item.job.parent_id"
                color="success"
                class="ml-2"
                size="14"
              >
                {{ $t('COMMON.DUPLICATE_ICON') }}
              </v-icon>
            </v-list-item-title>

            <v-list-item-subtitle>
              <v-icon size="12" :color="item.status > 0 ? 'success' : 'error'">mdi-circle</v-icon>
              <v-chip
                x-small
                label
                class="pl-1 ml-1"
                v-if="item.job.feed_id"
              >
                <v-icon class="mr-1" size="14">mdi-rss</v-icon>
                {{ getFeedItem(item.job.feed_id, 'title') }}
              </v-chip>
              <v-chip
                x-small
                label
                class="pl-1 ml-1"
                v-if="item.job.company"
              >
                <v-icon class="mr-1" size="14">mdi-domain</v-icon>
                {{ item.job.company }}
              </v-chip>
              <v-chip
                x-small
                label
                class="pl-1 ml-1"
                v-if="item.job.location"
              >
                <v-icon class="mr-1" size="14">mdi-map-marker</v-icon>
                {{ formatLocation(item.job.location) }}
              </v-chip>
              <v-chip
                x-small
                label
                class="pl-1 ml-1"
                v-if="item.job.xtramile_sector_id"
              >
                <v-icon class="mr-1" size="14">mdi-bookmark</v-icon>
                {{ $t('JOB.SECTOR.' + item.job.xtramile_sector_id) }}
              </v-chip>
            </v-list-item-subtitle>
          -->
          </v-list-item-content>
          <v-list-item-action>
            <status-label :item="item" small />
          </v-list-item-action>

          <v-list-item-action class="ml-3 date">
            {{ $moment(item.updated_at).fromNow() }}
          </v-list-item-action>

          <v-list-item-action>
            <div>
              <v-btn
                icon
                class="ml-2"
                @click.stop="removeItem(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <!-- <a
                class="ml-2"
                :href="item.job.url"
                :title="item.job.url"
                target="blank"
              >
                <v-btn icon>
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </a> -->

              <v-btn
                icon
                class="ml-2"
                @click.stop="editItem(item)"
                >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </template>


        </List>

      </div>
    </div>

  </div>




</template>


<script>

import Loading from '@/components/Loading'
import List from '@/components/List'
import StatusLabel from '@/components/StatusLabel'
import FormCampaignJobPublicationMixins from '../FormCampaignJobPublicationMixins'

export default {
  name: 'FormPublicationJobCompact',
  mixins: [FormCampaignJobPublicationMixins],
  data: () => ({
    valid: false,
    loading: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    campaignItems: [],
    campaign: undefined,
    channelSubscriptionItems: [],
    publications: [],
  }),
  components: {
    List,
    Loading,
    StatusLabel,
  },
  props: {
    ids: {
      type: Array,
      default: () => ([])
    },
    props: {
      type: [Object, Boolean],
      default: false
    },
  },
  computed: {
    campaignId () {
      return this.props.campaign_id || undefined
    },
    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },
  },
  watch: {
    update () {
      this.loadItems()
    },
    select (items) {
      this.selected = items
    },
    campaign () {
      this.loadChannelSubscriptionItems()
    }
  },
  created () {
    // this.loadPublicationItems()
    this.loadCampaignItems()
    this.searchItems()
  },
  methods: {


        getImage (item, size = "original") {
          const url = `${process.env.VUE_APP_ASSETS}/channels/`
          return `${url + item.controller_name.toLowerCase()  }.png`
        },

        getReportCount (item) {
          const report = item.job.status_report
          if (report) return Object.keys(report).filter(k => typeof report[k] === 'object').length
          return 0
        },


        formatLocation (str) {
          return (str || '').split(',').slice(0,2).join(',')
        },

        getFeedItem (feed_id, field) {
          // for (let i = 0, len = this.feedItems.length; i < len; i++) {
          //   const c = this.feedItems[i]
          //   if (c.id === feed_id) return (field ? c[field] : c)
          // }
          return '?'
        },

    // loadPublicationItems () {
    //   if (this.campaign) {
    //     this.loading = true
    //     this.$services.api_programmatic.publication.search({ where: {campaign_id: this.campaign.id, status:[1]} })
    //     .then(response => {
    //       if (response.data) {
    //         this.publicationItems = response.data
    //       }
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    //   }
    // },


    loadCampaignItems () {
      this.loading = true
      this.$services.api_programmatic.campaign.search({ where: {status:[1]} })
        .then(response => {
          if (response.data) {
            this.campaignItems = response.data
            if (this.props.campaign_id) {
              for (let i = 0, len = this.campaignItems.length; i < len; i++) {
                if (this.campaignItems[i].id === this.props.campaign_id) {
                  this.campaign = this.campaignItems[i]
                  return this.loadChannelSubscriptionItems()
                }
              }
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadChannelSubscriptionItems () {
      if (this.campaign) {
        this.loading = true
        this.$services.api_programmatic.channel_subscription.search({ where: {methods: this.campaign.methods, status:[1], channel: true} })
        .then(response => {
          if (response.data) {
            this.channelSubscriptionItems = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })
      }
    },

    removeItems () {
      this.showEdit = false
      this.$router.push(`/xtrading/campaign/${this.campaignId}`)
      this.loadItems()
    },

    searchItems (element) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, 100)
    },

    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,
      }

      const query = this.$queryFormat({
        where: {status: 1, campaign_id: this.campaignId, job_id: this.ids[0]},
        options: Object.assign({}, defaultOptions, {options}),
      })
      this.$emit('childData', {queryPublicationList: query})
      this.$services.api_programmatic.publication.search(query)
        .then(response => {
          if (response.data) {
            this.items = response.data.items
            this.count = response.data.count
            this.offset = response.data.offset
            this.limit = response.data.limit
          } else {
            this.items = []
            this.count = 0
            this.offset = 0
            this.limit = 0
          }
          this.updateSelected()
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const td = v => v.id
      const ids = this.selected.map(td)
      this.$services.api_programmatic.publication.remove({ where: { id: ids }})
        .then(response => {
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
    },

    updateSelected () {
      const selected = [...this.selected]
      const items = [...this.items]
      for (let i = 0, lenI = selected.length; i < lenI; i++) {
        const a = selected[i]
        for (let j = 0, lenJ = items.length; j < lenJ; j++) {
          const b = items[j]
          if (a.id === b.id) {
            selected[i] = b
            break
          }
        }
      }
      this.selected = selected
    },

    // Mutliple items

    removeSelected () {
      this.showDialogRemove = true
    },

    editSelected () {
      this.showEdit = true
      if (this.selected.length > 0) {
  const item = this.selected[0]
        this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/?edit=${item.id}`)
      }
    },

    selectItem (item) {
      for (let i = 0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i,1)
      }
      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/`)
    },

    closePublish () {
      this.showPublish = false
      this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/`)
    },


    selectAllPages (options) {
      this.loading = true
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        attributes: ['id', 'updated_at'],
        limit: 100000,
      }
      const query = this.$queryFormat({
        where: {status: 1},
        options: Object.assign({}, defaultOptions, {options}),
      })

      this.$services.api_programmatic.publication.search(query)
        .then(response => {
          if (response.data) {
            this.selected = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })

    },

    // Single item

    updateItems() {
      this.loadItems()
    },

    newItem () {
      this.selected = []
      this.showNew = true
    },

    editItem (item) {
      this.selected = [item]
      this.$router.push(`/xtrading/campaign/${this.campaignId}/publications/?edit=${item.id}`)
      this.showEdit = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    goToItem (item) {
      // item = item ? item : this.selected[0]
      // const route = `/job/${  item.job.id}`
      // if (this.$router.currentRoute.path !== route) {
      //   this.$router.push(route)
      // }
    },

    applyFilters () {
      this.searchItems()
    },

    resetFilters () {
      this.applyFilters()
    }



  }


}



</script>
