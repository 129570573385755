<script>

export default {
  name: 'FormCampaignJobPublicationMixins',
  data: (_this) => ({
    loading: 0,
    current: 0,
    showSaveMultiple: false,
    fields: [
      'title',
      'url',
      'office_id',
      'category',
      'xtramile_occupation_id',
      'xtramile_sector_id',
      'date',
      'location',
      'contract_type',
      'contract_schedule',
      'contract_remote',
      'contract_status',
      'description',
      'profile',
      'tags',
      'company_title',
      'company_description',
      'company_url',
      'company_image_url',
      'contact_name',
      'contact_email',
      'salary_min',
      'salary_max',
      'salary_period',
      'salary_currency',
      'duration_min',
      'duration_max',
      'duration_period',
      'start_date',
      'end_date',
      'age_min',
      'age_max',
      'experience_level',
      'education_level',
      'diplomas',
      'quantity',
    ],
    // database values
    loaded_values: undefined,
    // default values
    default_values: {
      title: undefined,
      url: undefined,
      office_id: undefined,
      category: undefined,
      xtramile_occupation_id: undefined,
      xtramile_sector_id: undefined,
      date: undefined,
      location: undefined,
      contract_type: 'permanent',
      contract_schedule: 'fulltime',
      contract_remote: undefined,
      contract_status: 'employee',
      description: undefined,
      profile: undefined,
      tags: [],
      company_title: undefined,
      company_description: undefined,
      company_url: undefined,
      company_image_url: undefined,
      contact_name: undefined,
      contact_email: undefined,
      salary_min: undefined,
      salary_max: undefined,
      salary_period: 'year',
      salary_currency: 'EUR',
      duration_min: undefined,
      duration_max: undefined,
      duration_period: 'year',
      start_date: undefined,
      end_date: undefined,
      age_min: undefined,
      age_max: undefined,
      experience_level: undefined,
      education_level: undefined,
      diplomas: [],
      quantity: undefined,
    },
    // current values
    values: {
      title: undefined,
      url: undefined,
      office_id: undefined,
      category: undefined,
      xtramile_occupation_id: undefined,
      xtramile_sector_id: undefined,
      date: undefined,
      location: undefined,
      contract_type: 'permanent',
      contract_schedule: 'fulltime',
      contract_remote: undefined,
      contract_status: 'employee',
      description: undefined,
      profile: undefined,
      tags: [],
      company_title: undefined,
      company_description: undefined,
      company_url: undefined,
      company_image_url: undefined,
      contact_name: undefined,
      contact_email: undefined,
      salary_min: undefined,
      salary_max: undefined,
      salary_period: 'year',
      salary_currency: 'EUR',
      duration_min: undefined,
      duration_max: undefined,
      duration_period: 'year',
      start_date: undefined,
      end_date: undefined,
      age_min: undefined,
      age_max: undefined,
      experience_level: undefined,
      education_level: undefined,
      diplomas: [],
      quantity: undefined,
    },
  }),
  props: {
    ids: {
      type: [Array, Boolean],
      default: () => ([])
    },
    props: {
      type: [Object, Boolean],
      default: () => ({})
    }
  },
  watch: {
    ids (n,o) {
      if (n && n.length > 0) {
        if (n[this.current].id !== this.id) {
          this.current = 0
          this.loadItem()
        }
      }
    }
  },
  computed: {
    canPrev () {
      return this.current > 0 && this.ids.length > 0
    },
    canNext () {
      return this.current < this.ids.length-1 && this.ids.length > 0
    },
    id () {
      return this.loaded_values ? this.loaded_values.id : null
    }
  },
  created () {
    if (this.ids.length > 0) {
      this.loadItem()
    } else {
      this.resetItem()
    }
  },
  methods: {

    mapValueToObject (v) {
      return {value: v, text: this.$i18n.t(`JOB.${  v.toUpperCase()}`)}
    },

    // apply changes from another data (child component)
    handleInput (values) {
      values = this.$clone(values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') {
          if (values[field] instanceof Object && !(values[field] instanceof Array)) {
            this.values[field] = Object.assign({}, this.values[field], values[field])
          } else {
            this.values[field] = values[field]
          }
        }
      }
    },


    // init and reset values
    resetItem () {
      const values = this.$clone(this.id ? this.loaded_values : this.default_values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') this.values[field] = values[field]
      }
    },


    // load prev item on the fly
    prevItem () {
      if (this.canPrev) {
        this.current--
        return this.loadItem()
      }
    },


    // load next item on the fly
    nextItem () {
      if (this.canNext) {
        this.current++
        return this.loadItem()
      }
    },


    // load item
    loadItem () {
      this.loading = true
      const query = {
        where: {
          id: this.ids[this.current],
          publications: {
            campaign_id: this.props.campaign_id,
            status: [1],
          }
        }
      }
      return this.$services.api_programmatic.job.search(query)
        .then(response => {
          if (response.data && response.data[0]) {
            this.loaded_values = this.$clone(response.data[0])
          }
          return response
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.resetItem()
          this.loading = false
        })

    },


    // save item
    saveItem (multiple = false) {
      if (this.ids.length > 1 && (typeof multiple !== 'boolean' || !multiple)) return this.showSaveMultiple = true
      this.showSaveMultiple = false
      this.loading = true
      if (!this.id) {
        const values = {}
        for (const field of this.fields) {
          if (typeof this.values[field] !== 'undefined') values[field] = this.values[field]
        }
        return this.$services.api_programmatic.job.create({values})
          .then(response => {
            if (response.data) {
              this.$emit('create', response.data[1])
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        const values = this.$compare(this.loaded_values, this.values, this.fields)
        if (Object.keys(values).length > 0) {
          const ids = multiple && this.ids.length > 1 ? this.ids : this.id
          return this.$services.api_programmatic.job.update({values, where: {id: ids}})
            .then(response => {
              if (response.data) {
                this.$emit('update', ids)
              }
            })
            .catch(e => {
              this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      }
    },


    // remove item
    removeItem (multiple = true) {
      const ids = multiple && this.ids.length > 1 ? this.ids : this.id
      if (ids) {
        this.loading = true
        return this.$services.api_programmatic.job.remove({where: {id: ids}})
          .then(response => {
            if (response.data) {
              this.$emit('remove', ids)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        console.warning('You cannot remove element without its id.')
      }
    },


    // refresh
    refreshItem (multiple = true) {
      const ids = multiple && this.ids.length > 1 ? this.ids : this.id
      if (ids) {
        this.loading = true
        return this.$services.api_programmatic.job.refresh({where: {id: ids}})
          .then(response => {
            if (response.data) {
              this.$emit('update', ids)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },


  }
}
</script>
